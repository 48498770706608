import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CellHighlight } from '../../../utils/custom-cell';
import RoomOverviewChangeStatusModal from './RoomOverviewChangeStatusModal';

export const getIconsByType = (item) => {
  const icons = [];
  if (item.Arrival) icons.push("fa-solid fa-arrow-right-to-bracket");
  if (item.Departure) icons.push("fa-solid fa-door-open");
  if (item.Stayover) icons.push("fa-solid fa-person-arrow-down-to-line");
  return icons;
};

const RoomOverviewTableDetails = ({ records, reload, isLoading }) => {
  const { t } = useTranslation();
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [show, setShow] = useState(false);

  const handleCheckboxChange = (room) => {
    setSelectedRooms((prevSelected) => {
      // Check if the room is already selected
      if (prevSelected.some((selected) => selected.IdRoom === room.IdRoom)) {
        // If it's already selected, remove it from the state
        return prevSelected.filter((selected) => selected.IdRoom !== room.IdRoom);
      } else {
        // If it's not selected, add it to the state
        return [...prevSelected, room];
      }
    });
  };

  const closeChangeStatusModal = () => {
    setShow(false);
    reload();
  }

  useEffect(() => {
    setSelectedRooms([]);
  }, [records]);

  return (
    <>
      <RoomOverviewChangeStatusModal show={show} handleClose={closeChangeStatusModal} roomList={selectedRooms} />
      <Card>
        <Card.Body>
          <Row className='mb-4'>
            <Col className='d-flex justify-content-end'>
              <Button
                variant="primary" 
                onClick={() => setShow(true)}
                disabled={selectedRooms.length === 0}
              >{t("Change Status")}</Button>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col>
            <div style={{maxHeight: '350px', overflowY: 'auto'}}>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th className='text-center'></th>
                    <th className='text-center'>{t("Room")}</th>
                    <th className='text-center'>{t("ST")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Guest")}</th>
                    <th>{t("Floor")}</th>
                    <th>{t("View")}</th>
                    <th>{t("Section")}</th>
                    <th>{t("Building")}</th>
                    <th>{t("Arr/Dept")}</th>
                    <th>{t("Last Update")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  records.length > 0 ?
                  records
                    .sort((a, b) => a.RoomOrder - b.RoomOrder)
                    // sort by room wich is a text field
                    // .sort((a, b) => a.Room.localeCompare(b.Room))
                    .map((item, index) => {
                    const exist = selectedRooms.some((selected) => selected.IdRoom === item.IdRoom);
                    return (
                    <tr
                      key={`${item.IdRoom}-${index}`}
                      className={`${
                        exist ? "table-primary" : ""
                      }`}
                    >
                      <td className='text-center'>
                        <input 
                          type="checkbox"
                          onChange={() => handleCheckboxChange(item)}
                          checked={selectedRooms.some((selected) => selected.IdRoom === item.IdRoom)}
                        />
                      </td>
                      <td className='text-center'>
                        <span
                          className="badge w-75 fw-bold"
                          style={CellHighlight(item.BackgroundColor, item.TextColor)}
                        >
                          {item.Room}
                        </span>
                      </td>
                      <td className='text-center'>{item.st}</td>
                      <td>{item.RoomType}</td>
                      <td>{item.Guest}</td>
                      <td>{item.BuildingFloor}</td>
                      <td>{item.RoomView}</td>
                      <td>{item.BuildingSections}</td>
                      <td>{item.Building}</td>
                      <td>
                        {getIconsByType(item).map((icon, index) => (
                          <i key={index} className={`fs-4 mx-2 ${icon}`} />
                        ))}
                      </td>
                      <td>{item.LastStatusUpdate ? moment(item.LastStatusUpdate).format("DD/MM/YYYY hh:mm A") : ''}</td>
                    </tr>
                  )}): <tr><td colSpan={"11"} className="text-center">{isLoading ? t("Please wait") :t("No records found")}</td></tr>}
                </tbody>
              </Table>
            </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default RoomOverviewTableDetails
