import API from "../api/api";
import { parsedUser } from "./GetCurrentUser";

export const ConsultHashId = async (id) => {
  const user = parsedUser();
  try {
    const query = `token=${user.Token}&id=${id}`;
    const request = await API.getAction("api/selector", query);
    const results = request.data[0];
    const parsedResults = JSON.parse(results.JSONData)[0];

    if (parsedResults.Error) {
      throw new Error(parsedResults.Msg);
    }

    const recordResult = JSON.parse(parsedResults.JSONData);
    
    if (recordResult) {
      const parsedItems = recordResult.map((item) => ({
        id: item.Id,
        text: item.Description,
      }));

      return parsedItems;
    }

  } catch (error) {
    console.error(error);
    return [];
  }
};