import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../breadcrumb";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { GenericFields } from "../group-reservation/GenericFields";

import { CashFields } from "./fields/CashFields";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import { GetDataOfJSON } from "../../utils/GetDataOfJSON";

import { FormatMoney } from "../../utils/FormatMoney";
import { PrintCashClosing } from "./PrintCashClosing";
import Loading from "../Loading";
import { useToast } from "../../contexts/toast";
import { confirmAlert } from "react-confirm-alert";
import { USDollar } from "../../utils/number-to-currency";

const CashClosingContainer = ({ routeName }) => {
  const { t } = useTranslation();

  const user = parsedUser();
  const toast = useToast();

  const [showPrint, setShowPrint] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState(CashFields);
  const [accounts, setAccounts] = useState([]);
  const [closingHeader, setClosingHeader] = useState(null);
  // const [isInProcess, setIsInProcess] = useState(
  //   localStorage.getItem("status_cash") === "true" ? true : false
  // );

  const [isOpen, setIsOpen] = useState(false);
  const [validationsSchema, setValidationSchema] = useState(null);
  const [quantities, setQuantities] = useState({});
  const [totals, setTotals] = useState({});
  const sumTotals = Object.keys(totals).reduce((acc, total) => {
    const account = accounts[total][0];
    return acc + totals[total] * account.RateValue;
  }, 0);

  const handleQuantityChange = (account, denomination, value) => {
    const newQuantities = {
      ...quantities,
      [`${account}-${denomination}`]: value,
    };

    console.log(newQuantities, account, accounts);
    setQuantities(newQuantities);

    // Calculate new total for the account
    let newTotal = 0;
    accounts[account]
      .filter((item) => item.CurrencyCode === account)
      .forEach((item) => {
        console.log(item, account);
        newTotal +=
          item.Value * (newQuantities[`${account}-${item.Denomination}`] || 0);
      });
    //   }

    //   (acc, item) =>
    //     acc +
    //     item.Value * (newQuantities[`${account}-${item.Denomination}`] || 0),
    //   0
    // );

    setTotals({
      ...totals,
      [account]: newTotal,
    });
  };

  const RequestAccounts = async () => {
    try {
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;

      let queryData = {
        Data: null,
      };

      let requestAPI = await API.postAction(
        "/api/CashierClosing/BringCurrencies?" + queryString,
        queryData
      );
      console.log(requestAPI);

      let result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0).Record;

      let groupByCurrencyCode = Object.groupBy(
        result,
        ({ CurrencyCode }) => CurrencyCode
      );

      setAccounts(groupByCurrencyCode);
    } catch (error) {
      console.error(error);
    }
  };

  const changeToOnlyReadFields = (CashierStatus) => {
    const updatedFields = fields.map((item) => {
      if (
        CashierStatus && item.FieldName === "Cashfund"
      ) {
        return { ...item, ReadOnly: true };
      }
      return item;
    });
    setFields(updatedFields);
  };

  const checkCashierStatus = async () => {
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;

    let queryData = {
      Data: `@Accion='C'`,
    };
    console.log(queryData);

    let requestAPI = await API.postAction(
      "api/CashierClosing/generic?" + queryString,
      queryData
    );

    console.log("checks tatus ", requestAPI);

    if (requestAPI.status === 200) {
      const result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0);
      const status = result.Record[0].IsCashierOpen
      setIsOpen(status);
      
      if (status) {
        const CashierCashFund = result.Record[0].CashierCashFund;
        formik.setFieldValue("Cashfund", CashierCashFund);
        changeToOnlyReadFields(status);
        toast({
          type: "info",
          message: t("Cashier is Open"),
        })
      } 
      if (!status) {
        formik.resetForm();
        fillFormikObject();
        formik.setFieldValue("Cashfund", 0);
        RequestAccounts();
        clearTotalsQuantities();
        toast({
          type: "info",
          message: t("Cashier is Closed"),
        })
      }
      changeToOnlyReadFields(status);

      // toast({
      //   type: "success",
      //   message: status ? t("Cashier is Open") : t("Cashier is Closed"),
      // })
      // debugger;
      console.log(result);
    }
  };

  const SaveHeader = async (obj) => {
    try {
      // setIsInProcess(true);
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;

      let queryData = {
        Data: `@IdBussinessUnit=${user.IdBusinessUnit}, @Comment='${
          obj.comment || ""
        }'`,
      };
      console.log(queryData);

      let requestAPI = await API.postAction(
        "api/CashierClosing/SaveHeader?" + queryString,
        queryData
      );

      console.log("saveheader ", requestAPI);

      if (requestAPI.status === 200) {
        const result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0);
        const GUIDClosingHeader = result.Record[0].GUIDClosingHeader;
        setClosingHeader(GUIDClosingHeader);
        await SaveDetails(GUIDClosingHeader);
        await GenerateClousure(GUIDClosingHeader);
        // setHeader(result.Record[0]);
        console.log("result", result);
        
        fillFormikObject();
        // toast({
        //   type: "success",
        //   message: t("successfully"),
        // });
      }
    } catch (error) {
      toast({
        type: "error",
        message: t("Error while saving data"),
      });
      console.error(error);
    }
  };

  const SaveDetails = async (GUIDClosingHeader) => {
    try {
      setIsLoading(true);
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;

      for (const account in accounts) {
        for (const byAccount in accounts[account]) {
          let item = accounts[account][byAccount];
          let queryData = {
            Data: `@GUIDClosingHeader='${GUIDClosingHeader}', @GUIDDenominations='${
              accounts[account][byAccount].GUIDDenominations
            }', @Quantity=${
              quantities[`${account}-${item.Denomination}`]
            }, @TotalAmount=${item.Total}, @CurrencyRateValue=${
              item.RateValue
            }`,
          };

          /* let requestAPI = */ await API.postAction(
            "api/CashierClosing/SaveDetails?" + queryString,
            queryData
          );
        }
      }
      toast({
        type: "success",
        message: t("Details saved successfully"),
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        type: "error",
        message: t("Error save data"),
      });
      console.error(error);
    }
  };

  const GenerateClousure = async (GUIDClosingHeader) => {
    try {
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;
      let queryData = {
        Data: `@GUIDClosingHeader='${GUIDClosingHeader}', @IdBussinessUnit=${user.IdBusinessUnit}, @Accion='G'`,
      };

      let requestAPI = await API.postAction(
        "api/CashierClosing/generic?" + queryString,
        queryData
      );
      console.log(requestAPI);
      checkCashierStatus();
      toast({
        type: "success",
        message: t("Cashier closed successfully"),
      });
      setShowPrint(true);
    } catch (error) {
      toast({
        type: "error",
        message: t("Error save data"),
      });
      console.error(error);
    }
  };

  const OpenCash = async (obj) => {
    try {
    const queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;
    const queryData = { Data: `@Accion='O',@IdBussinessUnit=${user.IdBusinessUnit}, @CashierCashFund=${obj.Cashfund}` };
    const requestAPI = await API.postAction(
      "api/CashierClosing/generic?" + queryString,
      queryData
    );

    if (requestAPI.status === 200) {
      const result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0);
      checkCashierStatus();
      // changeToOnlyReadFields();
      toast({
        type: "success",
        message: t("Cashier opened successfully"),
        width: "auto"
      })
      console.log("🚀 ~ OpenCash ~ result:", result)
    }

    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: t("Error while opening cash"),
        width: "auto"
      })
    }

  };

  const handleSubmit = async (obj, event) => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to Open the Cash?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            // SaveHeader(obj);
            await OpenCash(obj);
            
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  const updateField = (fieldName, fieldValue) => {
    console.log(fieldName, fieldValue);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const fillFormikObject = () => {
    // console.log("record", record, "model", fields);
    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "IdBusinessUnit") {
          formik.setFieldValue(item.FieldName, user.IdBusinessUnit * 1);
        } else if (item.FieldName === "name") {
          formik.setFieldValue(item.FieldName, user.Username);
        } else if (item.FieldName === "OpeningDate") {
          formik.setFieldValue(item.FieldName, new Date());
        }
      });
    }
  };

  const prepareFormikObject = () => {
    // console.log("Preparing formik object", section);
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.FieldName === "Cashfund") {
          //item.ReadOnly = isInProcess;
          item.ReadOnly = isOpen;
        }

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    // console.log(initialObject);
    formik.initialValues = initialObject;
    setValidationSchema(yup.object(initialObjectValidation));
    //Nowws
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const clearTotalsQuantities = () => {
    const initialQuantities = {};
    const initialTotals = {};
    Object.keys(accounts).forEach((account) => {
      accounts[account]
        .filter((item) => item.CurrencyCode === account)
        .forEach((item) => {
          initialQuantities[`${account}-${item.Denomination}`] = 0;
        });

      initialTotals[account] = 0;
    });
    setQuantities(initialQuantities);
    setTotals(initialTotals);
  }

  useEffect(() => {
    clearTotalsQuantities();
  }, [accounts]);

  useEffect(() => {
    checkCashierStatus();
    // RequestAccounts();
  }, []);

  useEffect(() => {
    prepareFormikObject();
    fillFormikObject();
  }, [fields]);

  return (
    <>
      <CustomBreadcrumbs pathTitle={routeName} />
      {showPrint && (
        <PrintCashClosing
          // GUIDClosingHeader={"59165EE7-2C3C-499A-8822-EC9B624D2C97"}
          GUIDClosingHeader={closingHeader}
          show={showPrint}
          toggle={() => setShowPrint(false)}
        />
      )}

      <Container className="mt-0 pt-0 mb-5 mh-100">
        <Row className="mx-3">
          <Col xs={12}>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="dx-icon fa-solid fa-money-bill-trend-up me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{t("Cash Management")}</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>

            {isOpen ? (
              <Button
                variant="outline-dark"
                size="lg"
                onClick={async () => {
                  confirmAlert({
                    closeOnClickOutside: false,
                    message: t(
                      "Are you sure you want to Save Details and Generate Clousure?"
                    ),
                    buttons: [
                      {
                        label: t("Yes"),
                        onClick: async () => {
                          await SaveHeader(formik.values);
                          // await SaveDetails();
                          // await GenerateClousure();
                        },
                      },
                      {
                        label: t("No"),
                      },
                    ],
                  });
                }}
                /* disabled={isLoading} */
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {t("Close Cash")}
                    <br />
                    <i className={"fa-solid fa-sack-dollar"} />
                  </>
                )}
              </Button>
            ) : (
              <Button
                form="thisForm"
                variant="outline-success"
                size="lg"
                type="submit"
              >
                {t("Open Cash")}
                <br />
                <i className={"fa-solid fa-play"} />
              </Button>
            )}
            {/* <Button
              className="ms-2"
              variant="outline-primary"
              size="md"
              onClick={() => {
                setShowPrint(true);
              }}
            >
              Test BTN
            </Button> */}
            <hr />
          </Col>

          <Col xs={6}>
            <Row>
              <Col xs={12}>
                <Form onSubmit={formik.handleSubmit} id="thisForm">
                  <Row>
                    <GenericFields
                      fields={fields}
                      values={formik.values}
                      errors={formik.errors}
                      touched={formik.touched}
                      updateFields={updateField}
                    />
                  </Row>
                </Form>
              </Col>
              <Col xs={12}>
                <Container className="d-flex mt-3 bg-white p-1 m-1 rounded">
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-nowrap">{t("Currency")}</th>
                        <th className="text-nowrap">{t("Rate Value")}</th>
                        <th className="text-nowrap">{t("Total")}</th>
                        <th className="text-nowrap">{t("Total")} DOP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(totals).map((total, index) =>
                        [accounts[total][0]].map((account, fIndex) => {
                          return (
                            <React.Fragment key={fIndex}>
                              <tr>
                                <td className="text-no-wrap">
                                  {account.Currency}
                                </td>
                                <td>
                                  {USDollar(account.RateValue.toFixed(2))}
                                </td>
                                <td>{USDollar(totals[total])}</td>
                                <td className="text-wrap">
                                  {USDollar(
                                    (totals[total] * account.RateValue).toFixed(
                                      2
                                    )
                                  )}
                                </td>
                              </tr>

                              {Object.keys(totals).length - 1 === index ? (
                                <tr>
                                  <td>
                                    <h4>{t("Total")}</h4>
                                  </td>

                                  <td className="text-end" colSpan={3}>
                                    <h4>{USDollar(sumTotals.toFixed(2))}</h4>
                                  </td>
                                </tr>
                              ) : null}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </Container>
              </Col>
            </Row>
          </Col>
          {/* MONEY CALCULATOR */}
          <Col xs={6}>
            <Card style={{ backgroundColor: "#F4F4F4" }}>
              <Card.Header className="fs-bolder">
                {t("Calculate Money")}
              </Card.Header>
              <Card.Body>
                <Accordion>
                  {accounts &&
                    Object.keys(accounts).length > 0 &&
                    Object.keys(accounts).map((account, index) => (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header className="text-nowrap mb-0 mt-0">
                          {account} - {FormatMoney(totals[account])}
                        </Accordion.Header>

                        <Accordion.Body>
                          <Row>
                            <Col xs={12} className="table-responsive">
                              <table className="table table-striped text-center">
                                <thead>
                                  <tr>
                                    <th className="text-nowrap">
                                      {t("Denomination")}
                                    </th>
                                    <th className="text-nowrap">
                                      {t("Quantity")}
                                    </th>
                                    <th className="text-nowrap">
                                      {t("Amount")}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {accounts[account]
                                    .filter(
                                      (item) => item.CurrencyCode === account
                                    )
                                    .map((item, fIndex, array) => {
                                      const key = `${account}-${item.Denomination}`;

                                      item.Total =
                                        item.Value * (quantities[key] || 0);

                                      return (
                                        <React.Fragment key={fIndex}>
                                          <tr>
                                            <td className="text-no-wrap">
                                              {item.Denomination}
                                            </td>
                                            <td>
                                              {item.CalculationType === "Q" &&
                                              isOpen ? (
                                                <input
                                                  type="number"
                                                  className="form-control text-center"
                                                  defaultValue={
                                                    quantities[key] || 0
                                                  }
                                                  min={0}
                                                  onChange={(e) => {
                                                    handleQuantityChange(
                                                      account,
                                                      item.Denomination,
                                                      parseInt(
                                                        e.target.value
                                                      ) || 0
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <span>
                                                  {item.CalculationType === "Q"
                                                    ? quantities[key] || 0
                                                    : item.Value}
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              {["A", "F"].includes(
                                                item.CalculationType
                                              ) && isOpen ? (
                                                <input
                                                  type="number"
                                                  className="form-control text-end"
                                                  min={0}
                                                  defaultValue={
                                                    item.Value *
                                                    (quantities[key] || 0)
                                                  }
                                                  onChange={(e) =>
                                                    handleQuantityChange(
                                                      account,
                                                      item.Denomination,
                                                      parseInt(
                                                        e.target.value
                                                      ) || 0
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <span>
                                                  {FormatMoney(
                                                    item.Value *
                                                      (quantities[key] || 0)
                                                  )}
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                          {array.length - 1 === fIndex ? (
                                            <tr>
                                              <td>Total</td>
                                              <td></td>
                                              <td className="text-end">
                                                <span className="text-end">
                                                  {FormatMoney(
                                                    totals[account] || 0
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                          ) : null}
                                        </React.Fragment>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CashClosingContainer;
