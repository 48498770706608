import { DataGrid } from 'devextreme-react';
import { Column, ColumnFixing, GroupItem, GroupPanel, Grouping, Pager, Paging, Selection, Summary, TotalItem } from 'devextreme-react/data-grid';
import React, { forwardRef } from 'react';
import { dateFormater, timestampFormater } from '../../../utils/date-formater';

const allowedPageSizes = [10, 20, 50, 100, "all"];

const CustomReportsDataGrid = forwardRef((props, ref) => {
  const { records, summary, headers, hasPagination = true } = props;

  const GroupCell = options => <div>{options.value}</div>;
  // const headerCellRender = (options) => <b>{options.column.caption}</b>
  // const cellRender = (data) => <b>{data.value}</b>
;

  return (
    <DataGrid
      ref={ref}
      dataSource={records}
      columnAutoWidth={true}
      showBorders={true}
      className="grid"
      loadPanel={{
        enabled: true,
        showIndicator: true,
        text: "Loading...",
      }}
    >
      <ColumnFixing enabled={true} />
      <Grouping autoExpandAll={true} />
      <GroupPanel visible={false} />
      <Summary>
        {summary?.Group &&
          summary?.Group.map((item, index) => {
            return (
              <GroupItem
                key={index}
                column={item?.FieldName}
                summaryType={item?.SummaryType}
                displayFormat={item?.DisplayFormat}
                valueFormat={item?.FormatValue}
                showInColumn={item?.DisplayInColumn}
                showInGroupFooter={item?.DisplayInGroupFooter}
              />
            );
          })}
        {summary?.Total &&
          summary?.Total.map((item, index) => {
            return (
              <TotalItem
                key={index}
                column={item?.FieldName}
                summaryType={item?.SummaryType}
                displayFormat={item?.DisplayFormat}
                valueFormat={item?.FormatValue}
                showInColumn={item?.DisplayInColumn}
              />
            );
          })}
        {summary?.GrandTotal &&
          summary?.GrandTotal.map((item, index) => {
            return (
              <TotalItem
                key={index}
                column={item?.FieldName}
                summaryType={item?.SummaryType}
                displayFormat={item?.DisplayFormat}
                valueFormat={item?.FormatValue}
                showInColumn={item?.DisplayInColumn}
              />
            );
          })}
      </Summary>
      <Selection mode="single" />
      {headers && headers.map((item, index) => {
        if (item.SubColumns) {
          return (
            <Column
              key={index}
              caption={item?.FieldTitle}
              dataField={item?.FieldName}
              wordWrapEnabled={true}
              groupIndex={item?.groupIndex}
              groupCellRender={GroupCell}

              format={item.FormatValue.length > 0 ? JSON.parse(item?.FormatValue) : null}
              alignment={"center"}
            >
              {item.SubColumns.map((subItem, subIndex) => {
                return (
                  <Column
                    key={subIndex}
                    caption={subItem?.FieldTitle}
                    dataField={subItem?.FieldName}
                    wordWrapEnabled={true}
                    groupIndex={subItem?.groupIndex}
                    groupCellRender={GroupCell}
                    format={subItem?.FormatValue}
                  // alignment={"center"}
                  />
                );
              })}
            </Column>
          );
        }
        if (item.FieldType === "timestamp") {
          return (
            <Column
              key={index}
              caption={item?.FieldTitle}
              dataField={item?.FieldName}
              dataType={'date'}
              wordWrapEnabled={true}
              groupIndex={item?.groupIndex}
              groupCellRender={GroupCell}

              format={dateFormater}
            />
          );
        }

        if (item.FieldType === "fulltimestamp") {
          return (
            <Column
              key={index}
              caption={item?.FieldTitle}
              dataField={item?.FieldName}
              dataType={'date'}
              wordWrapEnabled={true}
              groupIndex={item?.groupIndex}
              groupCellRender={GroupCell}

              format={timestampFormater}
            />
          );
        }
        return (
          <Column
            caption={item?.FieldTitle}
            key={index}
            dataField={item?.FieldName}
            wordWrapEnabled={true}
            groupIndex={item?.groupIndex}
            groupCellRender={GroupCell}
            // headerCellRender={headerCellRender}
            // cellRender={cellRender}
            format={item?.FormatValue}
          />
        );
      })}
      
      <Paging defaultPageSize={10} enabled={hasPagination} pageSize={10} />
      {hasPagination ? 
      <Pager
        showInfo={true}
        infoText={"Page:"}
        visible={true}
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
      /> : null}
    </DataGrid>
  )
})

export default CustomReportsDataGrid;