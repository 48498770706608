import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { parsedUser } from "../../../utils/GetCurrentUser";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import { dateFormater } from "../../../utils/date-formater";
import TransferBusinessUnit from "../TransferBusinessUnit";

import { useTranslation } from "react-i18next";
import ShareRoom from "../ShareRoom/ShareRoom";

//ATENTION
/**
 * collapse the following two
 * arrangements for a cleaner view
 */

const AdvancedRoomSelection = ({ show, toggle, selectedReservation }) => {
  const { t } = useTranslation();

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);
  const [showTransferBusinessUnit, setShowTransferBusinessUnit] =
    useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  // const [allRoomType, setAllRoomType] = useState(false);
  // const [reservationSpecialRequests, setReservationSpecialRequests] = useState(true);
  // const [shareRoom, setShareRoom] = useState(false);
  const [virtualRoom, setVirtualRoom] = useState(true);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [roomTypes, setRoomTypes] = useState({
    showAllRooms: false,
    SpecialRequestRooms: true,
  });

  const toast = useToast();
  const [features, setFeatures] = useState([]);
  const user = parsedUser();
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const clearAll = () => {
    setAvailableRooms([]);
    setRoomTypes({
      showAllRooms: false,
      SpecialRequestRooms: true,
    });
    setVirtualRoom(true);
    setSelectedFeatures([]);
  };

  useEffect(() => {
    setAvailableRooms([]);
  }, [virtualRoom, roomTypes]);

  const requestFeatures = async () => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDReservation=${selectedReservation.GUID}`;
      let request = await API.getAction(
        "api/Reservations/RoomsFeatures",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error(t("No data found"));
      }
      setFeatures(recordResult);

      //Check to fill selectedFeatures
      let selected = [];
      recordResult.forEach((item) => {
        if (item.Picked) {
          selected.push(item);
        }
      });
      setSelectedFeatures(selected);

      //console.log(recordResult);
    } catch (ex) {
      console.error(ex);
    }
  };

  const requestAvailableRooms = async () => {
    setAvailableRooms([]);
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      let data = `@GUIDReservation='${selectedReservation.GUID
        }', @ShowAllRoomType=${roomTypes.showAllRooms
        }, @ShareRoom=${1}, @ReservationSpecialRequests=${roomTypes.SpecialRequestRooms
        }, @VirtualRoom=${virtualRoom}`;

      if (selectedFeatures.length > 0) {
        let filterAvailable = "";
        selectedFeatures.forEach((item, index) => {
          filterAvailable += (index > 0 ? "," : "") + item.Id;
        });

        data += `, @FilterAvailableFeatures='${filterAvailable}'`;
      }
      if (!selectedBusinessUnit.IdBusinessUnit) {
        return;
      }
      data += ", @IdBusinessUnit=" + selectedBusinessUnit.IdBusinessUnit;
      let queryData = {
        Data: data,
      };

      let request = await API.postAction(
        "api/Reservations/AvailableRooms?" + query,
        queryData
      );

      let results = request.data[0];
      console.log(results);
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error(t("No data found"));
      }
      setAvailableRooms(recordResult);
      //console.log(recordResult);
    } catch (ex) {
      console.error(ex);
    }
  };

  const assignRoom = async () => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      let data = `@GUIDReservation='${selectedReservation.GUID}', @GUIDRoom='${selectedRoom.RoomGUID}'`;
      if (transferInformation) {
        data += `, @IdBusinessUnit=${transferInformation.IdBusinessUnit}, @Comment='${transferInformation.Comments}', @ApplyHotelChange=1`;
      }
      let queryData = {
        Data: data,
      };

      let request = await API.postAction(
        "api/Reservations/AssignRoom?" + query,
        queryData
      );

      let results = request.data[0];
      console.log(results);
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      // let recordResult = parsedResults;
      // if (recordResult === null) {
      //   throw new Error("No data found");
      // }

      toast({
        type: "success",
        message: t("Record saved successfully"),
      });
      toggle();
      //console.log(recordResult);
    } catch (ex) {
      console.error(ex);
    }
  };

  const [transferInformation, setTransferInformation] = useState(null);

  const toggleTransferBusinessUnit = (obj, transferInfo) => {
    console.log(showTransferBusinessUnit, obj);

    if (obj) {
      setSelectedBusinessUnit({
        IdBusinessUnit: obj.id,
        BusinessUnit: obj.text,
      });
      setTransferInformation(transferInfo);
    } else {
      debugger;
      setSelectedBusinessUnit({
        IdBusinessUnit: selectedReservation.IdBusinessUnit,
        BusinessUnit: selectedReservation.BusinessUnit,
      });
    }
    setShowTransferBusinessUnit(!showTransferBusinessUnit);
  };

  useEffect(() => {
    requestAvailableRooms();
  }, [selectedBusinessUnit]);

  const RoomsTable = ({ data }) => {
    // console.log(data);
    return (
      <div className="table-responsive text-start">
        <Table border striped>
          <thead>
            <tr>
              <th>{t("Room")}</th>
              <th>{t("Room Type")}</th>
              <th>{t("Rom View")}</th>
              <th>{t("Arrival Date")}</th>
              <th>{t("Departure Date")}</th>
              <th>{t("Guest Name")}</th>
              <th className="text-center">{t("Virtual Room")}</th>
              <th>{t("Building")}</th>
              <th>{t("Floor")}</th>
              {/* <th>{t("Rom View")}</th> */}
              <th>{t("Room Connecting")}</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => {
                // console.log(item, selectedRoom)
                return (
                  <tr
                    key={index}
                    className={`clickable ${selectedRoom && item.RoomGUID === selectedRoom.RoomGUID
                      ? "active"
                      : ""
                      }`}
                    onClick={() => setSelectedRoom(item)}
                  >
                    <td>
                      <div
                        className="badge"
                        style={{
                          backgroundColor: item.RoomStatusBackgroundColor,
                          color: item.RoomStatusTextColor,
                        }}
                      >
                        {item.Room}
                      </div>
                    </td>
                    <td className="text-nowrap">{item.RoomType}</td>
                    <td className="text-nowrap">{item.RoomView}</td>
                    <td className="text-nowrap">
                      {item.ArrivalDate && dateFormater(item.ArrivalDate)}
                    </td>
                    <td className="text-nowrap">
                      {item.DepartureDate && dateFormater(item.DepartureDate)}
                    </td>
                    <td className="text-nowrap">{item.GuestName}</td>
                    <td className="text-center text-nowrap">
                      {item.isVirtualRoom ? "Yes" : "No"}
                    </td>
                    <td className="text-nowrap">{item.Building}</td>
                    <td className="text-nowrap">{item.BuildingFloor}</td>
                    {/* <td className="text-nowrap">{item.RoomView}</td> */}
                    <td className="text-nowrap">{item.RoomConnecting}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  {t("No Records Found")}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  useEffect(() => {
    if (selectedReservation) {
      requestFeatures();
      //  requestAllBusinessUnit();
    }
    console.log(selectedReservation, selectedBusinessUnit);
    setSelectedBusinessUnit({
      IdBusinessUnit: selectedReservation.IdBusinessUnit,
      BusinessUnit: selectedReservation.BusinessUnit,
    });
  }, [selectedReservation]);

  const togglePickedFeature = (item) => {
    //check if exist
    let items = selectedFeatures;
    let exist = items.filter((x) => x.Id === item.Id)[0];
    if (exist) {
      //We need to remove it
      items = items.filter((x) => x.Id !== item.Id);
    } else {
      //we need to add it
      items.push(item);
    }

    setSelectedFeatures(items);
  };
  const AvailableFeaturesTable = ({ data }) => {
    // console.log(data);
    return (
      <div className="table-responsive">
        <Table border striped>
          <thead>
            <tr>
              <th>{t("Available Features")}</th>
              <th>{t("Picked")}</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => {
                let checked = selectedFeatures.filter(
                  (x) => x.Id === item.Id
                )[0];
                return (
                  <tr key={index}>
                    <td>{item.Description}</td>
                    <td className="text-center">
                      <Form.Check
                        inline
                        type={"checkbox"}
                        checked={checked}
                        onChange={() => togglePickedFeature(item)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  };
  const [showShareRoom, setShowShareRoom] = useState(false);

  const toggleShareRoom = () => {

    setShowShareRoom(!showShareRoom);
  }
  // console.log(selectedReservation)
  return (
    <>
      {showShareRoom && <ShareRoom toggle={toggleShareRoom} reservation={selectedReservation} />}
      {showTransferBusinessUnit ? (
        <TransferBusinessUnit
          show={showTransferBusinessUnit}
          toggle={toggleTransferBusinessUnit}
          reservation={selectedReservation}
        />
      ) : null}

      <Modal
        show={show}
        onHide={toggle}
        size="xl"
        backdrop={true}
        keyboard={false}
        className="modalRight"
        dialogClassName="modal-fullscreen"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("Advanced Room Selection")} -{" "}
            {selectedBusinessUnit?.BusinessUnit}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="bg-white border " xs={6} md={3}>
              <AvailableFeaturesTable data={features} />
            </Col>

            <Col className="text-center" xs={6} md={9}>
              {/* HEADER */}


              <Row>
                <Col xs={12} md={4} className="text-start">
                  <label>Reservation</label>
                  <input type="text" className="form-control" value={selectedReservation?.ReservationNumber} readOnly />
                </Col>
                <Col xs={12} md={4} className="text-start">
                  <label>Guest Name</label>
                  <input type="text" className="form-control" value={selectedReservation?.GuestName} readOnly />
                </Col>
                <Col xs={12}>
                  <hr />
                </Col>
              </Row>



              <div className="d-flex justify-content-end align-items-center">
                <div className="me-3 t">
                  <Button
                    variant="outline-secondary"
                    size="md"
                    onClick={toggleTransferBusinessUnit}
                  >
                    {t("Transfer Business Unit")}
                  </Button>
                </div>
                <div>
                  <Form.Check
                    inline
                    label={t("Show All Rooms")}
                    name="showAllRooms"
                    type={"radio"}
                    value={roomTypes.showAllRooms}
                    checked={roomTypes.showAllRooms}
                    onChange={(e) => {
                      setRoomTypes({
                        showAllRooms: true,
                        SpecialRequestRooms: false,
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    label={t("Special Request Rooms")}
                    name="specialRequestRooms"
                    type={"radio"}
                    value={roomTypes.SpecialRequestRooms}
                    checked={roomTypes.SpecialRequestRooms}
                    onChange={(e) => {
                      setRoomTypes({
                        showAllRooms: false,
                        SpecialRequestRooms: true,
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    label={t("Virtual Room")}
                    checked={virtualRoom}
                    name="virtualRoom"
                    type={"checkbox"}
                    value={virtualRoom}
                    onChange={(e) => {
                      setVirtualRoom(!virtualRoom);
                    }}
                  />
                </div>
                <div className="my-2">
                  <Button
                    variant={"primary"}
                    size="sm"
                    className={" ms-2"}
                    onClick={() => {
                      requestAvailableRooms();
                    }}
                  >
                    {t("Check Availability")}
                  </Button>
                  <Button
                    variant={"secondary"}
                    size="sm"
                    className={" ms-2"}
                    onClick={() => {
                      clearAll();
                    }}
                  >
                    <i className="fas fa-eraser" />
                  </Button>
                </div>
              </div>
              <Card>
                <Card.Body>
                  <RoomsTable data={availableRooms} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100">
            <Col>
              <button className="btn btn-outline-primary" onClick={() => {
                toggleShareRoom()
              }}>
                {t("Share Room")}
              </button>
            </Col>
            <Col className="text-end">
              {selectedRoom ? (
                <Button
                  variant={"warning"}
                  size="lg"
                  className={""}
                  onClick={() => {
                    assignRoom();
                  }}
                >
                  <i className={"fa-solid fa-sign-in"} /> {t("Assign")}
                </Button>
              ) : null}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default AdvancedRoomSelection;
