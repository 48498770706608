import "devextreme/data/odata/store";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Container, Dropdown } from "react-bootstrap";
import { CustomBreadcrumbs as Breadcrumbs, CustomReportsDataGrid, ReportFilter } from "../../../components";
import { exportDataGrid as ExcelExportDataGrid } from 'devextreme/excel_exporter';
import { getFilterCriteria, handleFilter } from "../../../utils/filter-utils";
import { isTimestamp } from "../../../utils/date-formater";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { saveAs } from "file-saver-es";
import { useLocation } from "react-router-dom";
import { useToast } from "../../../contexts/toast";
import { Workbook } from "exceljs";
import API from "../../../api/api";
import modeljson from '../ReportDataFake/Incomes607Report/Incomes607ReportModel.json';
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import recordsJson from '../ReportDataFake/Incomes607Report/Incomes607ReportRecords.json';
import useFavorite from "../../../hooks/useFavorite";

export default function Incomes607Report() {
  // PROPERTIES
  const user = parsedUser();
  const { pathname } = useLocation();
  const dataGridRef = React.useRef(null);
  const toast = useToast();

  const [filters] = useState(modeljson.FilterFieldSet);
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setFilterBy] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [records, setRecords] = useState([]);
  const [summary, setSummary] = useState([]);
  const [showReport, setShowReport] = useState(false);

  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  // END PROPERTIES

  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR RECORDS /api/Reports/ReporIncomes607
  const bindDataRemote = async (filter) => {
    clearReport();
    setIsLoading(true);

    try {
      const query = `IdUser=${user.IdUser}&token=${user.Token}`;
      const queryData = {
        Data: filter,
      };
      const request = await API.postAction(`api/reports/ReporIncomes607?${query}`, queryData);
      const results = await request.data[0];
      const parsedResults = JSON.parse(results.JSONData)[0];

      const recordResult = parsedResults?.Records || [];
      const headerResult = parsedResults?.Headers || [];

      setRecords(recordResult);
      setHeaders(headerResult);

    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: "Error loading data",
        width: "auto",
        height: "auto",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const generateReport = useCallback(async (obj) => {
    if (obj.IdBusinessUnitGroup && obj.IdBusinessUnit) {
      toast({
        type: "error",
        message: "Please select either Business Unit Group or Business Unit, not both.",
        width: "auto",
      });
      return;
    }

    if (!obj.IdBusinessUnitGroup && !obj.IdBusinessUnit) {
      toast({
        type: "error",
        message: "Please select a Business Unit or Business Unit Group.",
        width: "auto",
      });
      return;
    }
    const ReportCriteria = await getFilterCriteria(obj, filters);
    setFilterBy(ReportCriteria);

    try {
      const ObjVersion = handleFilter(obj, filters);
      await bindDataRemote(ObjVersion);
      setSummary(recordsJson.Summary);
      setShowReport(true);
    } catch (error) {
      console.log(error);
    }
  }, [filterBy]);

  const clearReport = () => {
    try {
      setShowReport(false);
    } catch (error) {
      console.log(error);
    }
  }

  const addFavorite = async () => {
    const newFavorite = {
      icon: `${formIcon}`,
      path: pathname,
      formPath: formPath,
      title: formTitle,
    };
    UpdateFavorites(newFavorite);
  };

  const [formIcon] = useState("far fa-file-alt");
  const [formPath] = useState(null);
  const [formTitle] = useState(modeljson.ReportTitle);
  /**
   * Handles the exporting of data grid based on the specified format.
   * @param {Object} e - The event object containing the format and component information.
   * @param {string} format - The format to be exported to.
   * @returns {void}
   */
  const onExporting = useCallback((e, format) => {
    const GridInstance = e.current.instance;

    // Exporting to EXCEL
    if (format === "excel") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      ExcelExportDataGrid({
        component: GridInstance,
        worksheet,
        autoFilterEnabled: true,
        customizeCell: ({ gridCell, excelCell }) => {
          if (isTimestamp(excelCell.value) === true) {
            const formattedDate = moment(excelCell.value).format("MM/DD/YYYY");
            excelCell.value = formattedDate;
          }
          if (excelCell.value === false) {
            excelCell.value = "No";
          } else if (excelCell.value === true) {
            excelCell.value = "Yes";
          }
        },
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx"
          );
        });
      });
    }

    // Exporting to CSV
    if (format === 'csv') {
      const workbook = new Workbook();
      ExcelExportDataGrid({
        component: GridInstance,
        worksheet: workbook.addWorksheet("Main sheet"),
      }).then(() => {
        workbook.csv.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Report.csv"
          );
        }
        );
      });
    }
  }, [filterBy]);

  // END METHODS

  //EVENTS
  useEffect(() => {
    checkIfPinned();
  }, []);

  // END EVENTS

  return (
    <React.Fragment>
      {formPath && <Breadcrumbs pathTitle={formPath} />}
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    <i className={formIcon + " me-3 fa-2x"}></i>
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{formTitle}</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">
                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => addFavorite()}>
                      {isPinned ? "Remove from" : "Add to"} favorites
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* FILTERS */}
            <ReportFilter
              filterFields={filters}
              isLoading={isLoading}
              generateReport={generateReport}
              clearReport={clearReport}
            />
            {/* RECORDS */}
            {showReport &&
              <>
                <div className='mx-3 d-flex justify-content-end'>
                  <button className={'btn btn-outline-secondary mx-2 mb-2'} onClick={() => onExporting(dataGridRef, "excel")} disabled={records?.length === 0}>Excel</button>
                  <button className={'btn btn-outline-secondary mx-2 mb-2'} onClick={() => onExporting(dataGridRef, "csv")} disabled={records?.length === 0}>CSV</button>
                </div>
                <CustomReportsDataGrid
                  records={records}
                  summary={summary}
                  headers={headers}
                  ref={dataGridRef}
                />
              </>}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
