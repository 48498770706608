import moment from "moment";
import { dateFormater, timestampFormater } from "../../../utils/date-formater";
import { USDollar } from "../../../utils/number-to-currency";

export const cashClosingReport = (closingHeader, closingDetails = [], TransactionTotal = [], AmountTotal = []) => {
  const {
    BusinessUnit,
    ClosingDate,
    OpenDate,
    ClosingUserName,
    Comment,
    // Sequence,
  } = closingHeader;

  const denominationsDOP = closingDetails
  .filter((detail) => detail.ISOCode === 'DOP')
  .map((detail) => {
      return {
        Quantity: detail.Quantity,
        Denomination: detail.Denomination,
        TotalAmount: detail.TotalAmount,
      }
  })

  const denominationUSD = closingDetails
  .filter((detail) => detail.ISOCode === 'USD')
  .map((detail) => {
      return {
        Quantity: detail.Quantity,
        Denomination: detail.Denomination,
        TotalAmount: detail.TotalAmount,
      }
  });
  
  const denominationsEUR = closingDetails
  .filter((detail) => detail.ISOCode === 'EUR')
  .map((detail) => {
      return {
        Quantity: detail.Quantity,
        Denomination: detail.Denomination,
        TotalAmount: detail.TotalAmount,
      }
  });
  
  const TransactionTotal_ = TransactionTotal.map((transaction) => {
    return {
      Denomination: transaction.Denomination,
      TotalAmount: transaction.TotalAmount,
    }
  });

  /**
   *     "AmountTotal": [
      {
        "ISOCode": "DOP",
        "Cunrency": "DOMINICAN PESO",
        "IdCurrency": 2,
        "TotalAmount": 0,
        "TotalAmountBase": 0,
        "AvgCurrencyRateValue": 1
      },
      {
        "ISOCode": "EUR",
        "Cunrency": "EURO",
        "IdCurrency": 3,
        "TotalAmount": 0,
        "TotalAmountBase": 0,
        "AvgCurrencyRateValue": 62.5
      },
      {
        "ISOCode": "USD",
        "Cunrency": "US DOLLARS",
        "IdCurrency": 1,
        "TotalAmount": 100,
        "TotalAmountBase": 6000,
        "AvgCurrencyRateValue": 60
      }
   */

  const AmountTotal_ = AmountTotal.map((amount) => {
    return {
      ISOCode: amount.ISOCode,
      Currency: amount.Cunrency,
      TotalAmount: amount.TotalAmount,
      TotalAmountBase: amount.TotalAmountBase,
      AvgCurrencyRateValue: amount.AvgCurrencyRateValue,
    }
  });

  // console.log(closingDetails)
  return {
    header: function (currentPage, pageCount, pageSize) {
      return {
        columns: [
          {
            image: 'snow', width: 175,
            height: 80,
            margin: [20, 20, 0, 20],
          },
          { text: `Page ${currentPage} of ${pageCount}`, alignment: 'right', fontSize: 8, margin: [0, 20, 20, 0] }
        ]
      }
    },
    // footer: {
    //   text: `Official currency in Dominican Republic is Dominican Peso (DOP), all transactions are conducted in this currency, amounts in US Dollar are for your reference only and are subjected to change sooording with the rate of exchange fluotuations driven by macroeconomic factors for which the hotel is not responsible. I agree to pay for any balance left unpaid by the company, organization or person in charged and am aware of my responsability to cover any damage caused to the property.`,
    //   alignment: 'justify',
    //   fontSize: 8,
    //   margin: [40, 20, 40, 20],
    // },
    pageMargins: [40, 80, 40, 75],
    content: [
      {
        text: 'Cash Closing Report',
        bold: true,
        fontSize: 16,
        margin: [0, 20, 0, 20],
        alignment: 'center',
      },
      {
        layout: 'noBorders',
        table: {
          widths: [60, '*', 60, '*'],
          margin: [20, 20],
          body: [
            [
              {
                text: 'Hotel:',
                fontSize: 10,
                bold: true,
              },
              {
                text: BusinessUnit,
                fontSize: 10,
                bold: false,
              },
              {
                text: 'Open Date:',
                fontSize: 10,
                bold: true,
              },
              {
                text: timestampFormater(OpenDate),
                // text: moment(OpenDate).format('MM/DD/YYYY HH:mm:ss'),
                fontSize: 10,
                bold: false,
              },
            ],
            [
              {
                text: 'User:',
                fontSize: 10,
                bold: true,
              },
              {
                text: ClosingUserName,
                fontSize: 10,
                bold: false,
              },
              {
                text: 'Closing Date:',
                fontSize: 10,
                bold: true,
              },
              {
                text: timestampFormater(ClosingDate),
                fontSize: 10,
                bold: false,
              },
            ],
            [
              {
                text: 'Comments:',
                fontSize: 10,
                bold: true,
              },
              {
                text: Comment,
                // text: Comment,
                fontSize: 10,
                bold: false,
                colSpan: 3,
              },
              {
            
              },
              {

              },
            ],
          ]
        }
      },
      {
        text: '',
        bold: true,
        fontSize: 8,
        margin: [0, 10],
        alignment: 'center',
      },
      {
        columnGap: 15,
        columns: [
          // First Column
          {
            stack: [
              {
                text: 'Desglose de Efectivo EUR',
                bold: true,
                fontSize: 12,
                margin: [0, 10],
              },
              {
                layout: 'lightHorizontalLines',
                table: {
                  widths: ['auto', 'auto', 75],
                  body: [
                    [
                      {
                        text: 'Denominación',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Cantidad',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Total',
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                    ],
                    ...denominationsEUR.map((denomination, index) => {
                      if (index === denominationsEUR.length - 1) {
                        return [
                          {
                            text: "Total EUR",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: "",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: USDollar(denominationsEUR.reduce((acc, curr) => acc + curr.TotalAmount, 0)),
                            fontSize: 10,
                            alignment: 'right',
                          },
                        ]
                      }
                      
                      return [
                      {
                        text: denomination.Denomination,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: denomination.Quantity,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: USDollar(denomination.TotalAmount),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]}),
                  ],
                },
              },
              {
                text: 'Desglose de Efectivo DOP',
                bold: true,
                fontSize: 12,
                margin: [0, 10],
              },
              {
                layout: 'lightHorizontalLines',
                table: {
                  widths: ['auto', 'auto', 75],
                  body: [
                    [
                      {
                        text: 'Denominación',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Cantidad',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Total',
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                    ],
                    ...denominationsDOP.map((denomination, index) => {
                      if (index === denominationsDOP.length - 1) {
                        return [
                          {
                            text: "Total DOP",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: "",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: USDollar(denominationsDOP.reduce((acc, curr) => acc + curr.TotalAmount, 0)),
                            fontSize: 10,
                            alignment: 'right',
                          },
                        ]
                      }
                      return [
                      {
                        text: denomination.Denomination,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: denomination.Quantity,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: USDollar(denomination.TotalAmount),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]}),
                  ],
                },
              },
            ],
          },
          // Second Column
          {
            stack: [
              {
                text: 'Desglose de Efectivo USD',
                bold: true,
                fontSize: 12,
                margin: [0, 10],
              },
              {
                layout: 'lightHorizontalLines',
                table: {
                  widths: ['auto', 'auto', 75],
                  body: [
                    [
                      {
                        text: 'Denominación',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Cantidad',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Total',
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                    ],
                    ...denominationUSD.map((denomination, index) => {
                      if (index === denominationUSD.length - 1) {
                        return [
                          {
                            text: "Total USD",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: "",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: USDollar(denominationUSD.reduce((acc, curr) => acc + curr.TotalAmount, 0)),
                            fontSize: 10,
                            alignment: 'right',
                          },
                        ]
                      }
                      
                      return [
                      {
                        text: denomination.Denomination,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: denomination.Quantity,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: USDollar(denomination.TotalAmount),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]}),
                  ],
                },
              },
              {
                text:"",
                margin: [0, 10],
              },
              {
                text: 'Summary',
                bold: true,
                fontSize: 12,
                margin: [30, 10],
              },
              {
                layout: 'noBorders',
                // widths: [100, 100],
                table: {
                  widths: [100, 100],
                  body: [
                    ...TransactionTotal_.map((transaction) => [
                      {
                        text: transaction.Denomination,
                        bold: true,
                        fontSize: 10,
                        alignment: 'left',
                      },
                      {
                        text: USDollar(transaction.TotalAmount),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]),
                  ],
                },
              },
              {
                text:"",
                margin: [0, 10],
              },
              {
                text: 'Sumatoria Transacciones',
                bold: true,
                fontSize: 12,
                margin: [30, 10],
              },
              {
                layout: 'noBorders',
                // widths: [100, 100],
                table: {
                  widths: [95, 50, 50],
                  body: [
                    ...AmountTotal_.map((amount) => [
                      {
                        text: amount.Currency,
                        bold: true,
                        fontSize: 10,
                        alignment: 'left',
                      },
                      {
                        text: USDollar(amount.TotalAmountBase),
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: USDollar(amount.TotalAmount),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]),
                  ],
                },
              },
            ],
          },
        ],
      },
      // {
      //   text: 'Summary',
      //   bold: true,
      //   fontSize: 12,
      //   margin: [0, 10],
      // },
      // {
      //   layout: 'noBorders',
      //   // widths: [100, 100],
      //   table: {
      //     widths: [100, 100],
      //     body: [
      //       ...TransactionTotal_.map((transaction) => [
      //         {
      //           text: transaction.Denomination,
      //           bold: true,
      //           fontSize: 10,
      //           alignment: 'left',
      //         },
      //         {
      //           text: USDollar(transaction.TotalAmount),
      //           fontSize: 10,
      //           alignment: 'right',
      //         },
      //       ]),
      //     ],
      //   },
      // },
    ],
    images: {
      snow: 'https://strapi.globalagents.net/uploads/Lifestyle_Holidays_Hotels_and_Resorts_Logo_9ed9d18972.jpg',
    }
  };
};
