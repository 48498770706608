import "devextreme/data/odata/store";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as yup from "yup";
import { useFormik } from "formik";
import { useLocation, useParams } from "react-router-dom";
import { CustomBreadcrumbs as Breadcrumbs } from "..";
import API from "../../api/api";
import { useToast } from "../../contexts/toast";
import useFavorite from "../../hooks/useFavorite";
import { parsedUser } from "../../utils/GetCurrentUser";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { exportToExcel as GenerateXLS } from "../../utils/export-to-excel";
import { exportToPDF as GeneratePDF } from "../../utils/export-to-pdf";
import Pagination from "../Pagination/Pagination";
import FieldSelector from "../customComponents/FieldSelector";
import RolesManagementFilter from "./RolesManagementFilter";
import { useTranslation } from "react-i18next";

export default function RolesManagementContainer() {
  // PROPERTIES
  const { t } = useTranslation();
  const user = parsedUser();
  const [openDetails, setOpenDetails] = useState(null);
  const { pathname } = useLocation();
  const params = useParams();
  const { idModule } = useParams();
  const toast = useToast();

  const [recordCount, setRecordCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null); //I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  const [objSPVersion, setObjSPVersion] = useState(""); //This object will be used to filter the data

  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  };
  const [section, setSection] = useState(null);
  const [fields, setFields] = useState([]);
  const [model, setModel] = useState(initialModel);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  const selectedItemRef = useRef(null);
  const [sortBy, setSortBy] = useState("Id");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  // END PROPERTIES

  //METHODS
  const toggleOpenDetails = (item) => {
    console.log("🚀 ~ toggleOpenDetails ~ item:", item)
    if (openDetails && openDetails.IdAppMenu === item.IdAppMenu) {
      setOpenDetails(null);
    } else {
      setOpenDetails(item);
    }
    if (openDetails !== null) {
      // Should open/load permissions
    }
  };
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = async (filter) => {
    setLoading(true);

    try {
      // console.log("===>", currentPage);
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}&Quantity=${quantity}&pageNumber=${currentPage}`;
      // query += "&IdBusinessUnit=" + user.IdBusinessUnit;
      let queryData = {
        Data: filter,
      };
      // console.log(query);
      let request = await API.postAction(
        `/api/Roles/RoleRecords?${query}`,
        queryData
      );
      let results = request.data[0];
      console.log("🚀 ~ bindDataRemote ~ results:", results);
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;

      let recordResult = parsedResults;

      if (recordResult === null) {
        setModel({
          ...model,

          data: {
            ...model.data,

            Rows: [],
          },
        });

        return;
        // throw new Error("No data found");
      }

      // Migrate to the new model
      let dataFromAPI = recordResult[0]
        ? recordResult[0].JSONConfig
          ? recordResult[0].JSONData
          : recordResult
        : [];
      // console.log("Results from record", recordResult);

      //TODO: | Use JSONConfig to show specific fields
      let gridData = null;
      let gridColumns =
        dataFromAPI && dataFromAPI.length > 0
          ? Object.keys(dataFromAPI[0]).filter((x) => !x.startsWith("Id"))
          : null;

      setModel({
        ...model,

        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });

      await bindDataCountRemote();

      // setData(recordResult)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const [features, setFeatures] = useState([]);
  const bindDataFeaturesRemote = async (filter) => {
    setLoading(true);

    try {
      console.log("===>", currentPage);
      if (selectedItem === null) return;

      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDUserRole=${selectedItem.GUID}`;

      console.log(query);
      let request = await API.getAction(
        "/api/Roles/CRUDcoreUserRoleDetails?" + query
      );
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      console.log("features", parsedResults);
      let recordResult = parsedResults;

      if (recordResult !== null) {
        // console.log("o.o es que devuelve null?");
        setFeatures(recordResult);

        return;
        // throw new Error("No data found");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const [detailsPermissions, setDetailsPermissions] = useState([]);
  const bindDataDetailsPermissions = async (filter) => {
    setLoading(true);

    try {
      console.log("===>", currentPage);
      if (selectedItem === null) return;

      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDUserRole=${selectedItem.GUID}`;

      console.log(query);
      let request = await API.getAction(
        "/api/Roles/CRUDcoreUserRoleDetailsPermissions?" + query
      );
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      console.log("features", parsedResults);
      let recordResult = parsedResults;

      if (recordResult !== null) {
        console.log("o.o es que devuelve null?");
        setFeatures(recordResult);

        return;
        // throw new Error("No data found");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTableHeader([]);
    bindDataRemote(objSPVersion);
    setSelectedItem(null);
  }, [params.id, idModule]);

  useEffect(() => {
    bindDataRemote(objSPVersion);
    setSelectedItem(null);
  }, [currentPage]);

  const bindDataCountRemote = async (cleanFilter) => {
    // First start loading the model

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}${
        idModule ? "&IdModule=" + idModule : ""
      }`;

      query = query + `&Quantity=${RecordsQuantity}&pageNumber=${currentPage}`;

      let queryData = {
        Data: "",
      };

      let request = await API.postAction(
        `api/Roles/RoleCount?${query}`,
        queryData
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const bindDataModelRemote = async (cleanFilter) => {
    try {
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}${
        idModule ? "&IdModule=" + idModule : ""
      }`;
      let requestSections = await API.getAction(
        `/api/Roles/RoleModel`,
        querySections
      );
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;

      //! If grid data stays null, we use the data from the API

      let titles = [
        "Audit Information",
        "Added by",
        "Date Added",
        "Modified by",
        "Last Modified",
      ];
      // console.log(recordResultSection);
      setSection(recordResultSection[0]);
      let fields = recordResultSection[0].Fields.filter(
        (item) => !titles.includes(item.FieldTitle)
      );
      setFields(fields);

      //Now try to fill the filters
      let fieldsFilter = fields.filter((x) => x.isFilter === true);
      // setFilters(fieldsFilter);
      console.log("🚀 ~ bindDataModelRemote ~ fieldsFilter:", fieldsFilter);
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  const [tableHeader, setTableHeader] = useState([]);

  const bindDataHeaderRemote = async (cleanFilter) => {
    try {
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}${
        idModule ? "&IdModule=" + idModule : ""
      }`;
      let requestSections = await API.getAction(
        `api/Roles/RolesHeader`,
        querySections
      );
      // console.log("Header", requestSections);
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      // console.log("Header 2", parsedResultsSections);
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;
      // console.log(recordResultSection);

      //! If grid data stays null, we use the data from the API

      let titles = [
        "Audit Information",
        "Added by",
        "Date Added",
        "Modified by",
        "Last Modified",
      ];
      // console.log(recordResultSection);

      setTableHeader(recordResultSection[0].Fields);
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${
        user.Token
      }&RecordId=${recordId}${idModule ? "&IdModule=" + idModule : ""}`;
      let request = await API.getAction(`api/Roles/RoleDetails`, query);
      let results = request.data[0];
      console.log("🚀 ~ bindDataDetailsRemote ~ results:", results);

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }

      let finalResult = parsedResults.JSONData;
      fillFormikObject(finalResult);
      setSelectedItem(null);
      bindDataFeaturesRemote(finalResult.GUID);
    } catch (error) {
      console.error(error);
    }
    //Fill object with new values
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      setFeatures([]);
      formik.resetForm();
    }

    if (action === "I") {
      formik.resetForm();
      setSelectedItem(null);
    }
    if (action === "U" || action === "C" || action === "D") {
      setOpen(!open);

      await bindDataDetailsRemote(selectedItem.GUID);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }
    if (open) {
      setFeatures([]);
    }

    // setGeneralError(null);
    setOpen(!open);
    if (reload) {
      bindDataRemote(objSPVersion);
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${
              selectedItem.GUID
            }${idModule ? "&IdModule=" + idModule : ""}`;
            await API.getAction(`api/Roles/RoleDelete`, query);
            await bindDataRemote(objSPVersion);
            setSelectedItem(null);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("record", record, "model", section);
    if (section && fields && fields.length > 0) {
      fields.forEach((item) => {
        if (item && item.FieldName.length > 0) {
          console.log(
            "field name",
            item.FieldName,
            "RecordValue ",
            record[item.FieldName]
          );
          formik.setFieldValue(item.FieldName, record[item.FieldName]);
        }
      });
      // bindDataFeaturesRemote();
    }

    //Now
  };

  const prepareFormikObject = () => {
    // console.log("Preparing formik object", section);
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    try {
      let actions = {
        I: "I",
        U: "U",
        D: "I",
      };

      if (actionMode === "D") {
        const { GUID, Id, ...rest } = obj;
        obj = rest;
      }

      let objSPVersion = "";
      fields.forEach((item) => {
        if (item.ReadOnly || item.HtmlType === "separator") return;

        if (item.HtmlType !== "separator") {
          objSPVersion +=
            (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
        }
        if (
          item.FieldType === "int" ||
          item.FieldType === "bool" ||
          item.FieldType === "bit" ||
          item.FieldType === "bigint"
        ) {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else if (item.HtmlType === "radio") {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? "null"
              : "'" + obj[item.FieldName] + "'";
        }
      });

      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${
        actions[actionMode]
      }${idModule ? "&IdModule=" + idModule : ""}`;
      let queryData = {
        Data: objSPVersion,
      };
      setLoading(true);
      let request = await API.postAction(
        `api/Roles/RoleAddEdit?${queryString}`,
        queryData
      );

      console.log("DATA", request);

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          toast({
            type: "error",
            message: response.Msg || "An error occurred",
          });
          return;
        } else {
          setSelectedItem(response.JSONData);

          fillFormikObject(response.JSONData);
        }

        // debugger;
        if (actionMode === "I") {
          setActionMode("U");
        } else {
          if (actionMode === "D") {
            // Means we are duplicating
            var responseRole = await API.getAction(
              `api/Roles/Duplicate`,
              `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${response.JSONData.Id}&GUID=${response.JSONData.GUID}&previousRecord=${formik.values.GUID}`
            );
          }

          toggleOpen(null, true);
        }

        toast({
          type: "success",
          message: "Record saved successfully",
        });
      }
      //    toggleOpen(null, true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const addFavorite = async () => {
    const newFavorite = {
      icon: `${section.FormIcon}`,
      path: pathname,
      formPath: section.FormPath,
      title: section.FormTitle,
    };
    UpdateFavorites(newFavorite);
  };

  const headerWithoutAuditFields = () => {
    return (
      tableHeader &&
      tableHeader.filter(
        (x) =>
          x.FieldTitle !== "Audit Information" &&
          x.FieldName !== "Id" &&
          x.FieldName !== "IdUserAdd" &&
          x.FieldName !== "DateAdd" &&
          x.FieldName !== "IdUserMod" &&
          x.FieldName !== "DateMod"
      )
    );
  };

  const exportToExcel = useCallback(async () => {
    GenerateXLS(model.data.Rows, `${section.FormTitle}.xlsx`);
  }, [model.data.Rows]);

  const exportToPDF = useCallback(async () => {
    const container = document.getElementById("contentContainer");
    GeneratePDF(container);
  }, []);

  const updateFeature = async (item, apply) => {
    console.log(item, apply, selectedItem);
    if (formik.values === null) return;

    let query = `IdUser=${user.IdUser}&token=${user.Token}`;
    let queryData = {
      Data: `@Accion = 'U', @IdAppMenu=${item.IdAppMenu}, @GUIDUserRole='${formik.values.GUID}', @Apply=${apply}`,
    };

    await API.postAction(
      "/api/Roles/CRUDcoreUserRoleDetailsAddEdit?" + query,
      queryData
    );
    let f = features.map((x) => {
      if (x.IdAppMenu === item.IdAppMenu) {
        x.Apply = apply;
      }
      return x;
    });
    // f.filter((x) => x.IdRoomFeature === item.IdRoomFeature)[0].Apply = apply;

    setFeatures(f);
  };
  // END METHODS

  // UI METHODS

  useEffect(() => {
    bindDataRemote(objSPVersion);
  }, [sortBy, sortByOrder]);

  const handleFilter = async (obj) => {
    setObjSPVersion(obj);
    await bindDataRemote(obj);
  };

  const loadGRIDUI = () => {
    return (
      <>
        <Container>
          <RolesManagementFilter
            handleFilter={handleFilter}
            count={recordCount}
            isLoading={loading}
          />
          <Card>
            <Card.Body>
              <Row>
                <div className="table-responsive">
                  <Table
                    hover
                    size="xl"
                    bordered
                    className="table table-bordered shadow-sm table-striped"
                  >
                    <thead>
                      <tr>
                        {headerWithoutAuditFields().map((item, index) => {
                          // console.log(item);
                          return (
                            <th
                              key={index}
                              className={`fw-bold clickable`}
                              onClick={() => {
                                // console.log(item, sortBy, sortByOrder)
                                setSortBy(item.FieldName);
                                if (item.FieldName === sortBy) {
                                  if (sortByOrder === "ASC") {
                                    setSortByOrder("DESC");
                                  } else {
                                    setSortByOrder("ASC");
                                  }
                                } else {
                                  setSortByOrder("ASC");
                                }
                              }}
                            >
                              {item.FieldTitle}{" "}
                              {sortBy === item.FieldName &&
                              sortByOrder === "ASC" ? (
                                <i className="fas fa-sort-up"></i>
                              ) : sortBy === item.FieldName &&
                                sortByOrder === "DESC" ? (
                                <i className="fas fa-sort-down"></i>
                              ) : null}{" "}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {model?.data &&
                        model.data.Rows.map((item, index) => {
                          // console.log("item", item, "header", tableHeader);
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                setSelectedItem(item);
                              }}
                              style={{ cursor: "pointer" }}
                              className={
                                selectedItem && selectedItem.GUID === item.GUID
                                  ? "active"
                                  : ""
                              }
                            >
                              {headerWithoutAuditFields().map(
                                (column, index2) => {
                                  return (
                                    <td key={index2} className={` `}>
                                      {typeof item[column.FieldName] ===
                                      "boolean" ? (
                                        <input
                                          type="checkbox"
                                          checked={item[column.FieldName]}
                                          className=""
                                        />
                                      ) : (
                                        item[column.FieldName]
                                      )}
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
                <Pagination
                  RecordsQuantity={RecordsQuantity}
                  currentPage={currentPage}
                  isLoading={loading}
                  recordCount={recordCount}
                  setCurrentPage={setCurrentPage}
                  setRecordsQuantity={setRecordsQuantity}
                  // totalPages={totalPages}
                />
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  };

  //EVENTS

  useEffect(() => {
    setOpen(false);
    setSelectedItem(null);
    setActionMode(null);
    setModel(initialModel);
    checkIfPinned();
    bindDataModelRemote();
    bindDataHeaderRemote();
    //bindDataRemote();

    return () => formik.resetForm();
  }, [params.id]);

  useEffect(() => {
    // console.log("section updated", model);
    prepareFormikObject();
  }, [section, fields]);

  useEffect(() => {
    // console.log("entro record quantity");
    bindDataRemote(objSPVersion);
  }, [RecordsQuantity]);

  useEffect(() => {
    if (open) {
      //Search features
      if (selectedItem) {
        // bindDataFeaturesRemote();
      }
    }
  }, [open, selectedItem]);

  // END EVENTS

  if (!section) {
    return <></>;
  }
  return (
    <React.Fragment>
      {open ? (
        <Modal
          show={open}
          onHide={() => toggleOpen(null)}
          backdrop="static"
          keyboard={false}
          size={"xl"}
          className={"modalRight "}
        >
          <Modal.Header closeButton>
            <Modal.Title>{section.FormTitle}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Row>
                    {fields.map((item, index) => {
                      return (
                        <Col
                          className={
                            item.cssClasss + (!item.Visible ? " d-none" : "")
                          }
                          key={index}
                        >
                          <label
                            className={
                              item.HtmlType === "separator"
                                ? `me-2 ${
                                    index !== 0 ? "mt-4 pt-4" : "mt-2"
                                  }  separatorTitle`
                                : "me-2 mt-3 "
                            }
                          >
                            {item.FieldTitle}
                            {item.Required ? (
                              <i className="required-asterisk ms-1 text-danger">
                                *
                              </i>
                            ) : null}
                          </label>
                          <br />

                          <FieldSelector
                            actionMode={actionMode}
                            model={item}
                            key={index}
                            obj={formik.values}
                            // limit longitudes
                            updateField={updateField}
                            value={formik.values[item.FieldName]}
                          ></FieldSelector>
                          {formik.errors[item.FieldName] ? (
                            <div className="invalid text-sm">
                              {formik.errors[item.FieldName]}
                            </div>
                          ) : null}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col className="border-left">
                  {/* TABS */}
                  <Row>
                    <Col>
                      <Card>
                        <Card.Header>
                          <Card.Title>Details</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Description</th>
                                <th>Apply</th>
                              </tr>
                            </thead>
                            <tbody>
                              {features &&
                                features.map((item, index) => {
                                  console.log("🚀 ~ features.map ~ item:", item)
                                  if (index === 0) {
                                    console.log(actionMode, item);
                                  }
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>
                                          <button
                                            className="btn btn-sm"
                                            onClick={() =>
                                              toggleOpenDetails(item)
                                            }
                                          >
                                            {openDetails &&
                                            openDetails.IdAppMenu === item.IdAppMenu ? (
                                              <i className="fa fa-chevron-down"></i>
                                            ) : (
                                              <i className="fa fa-chevron-right"></i>
                                            )}
                                          </button>
                                        </td>
                                        <td>{`${item.OptionMenuLevel1} > ${item.OptionMenuLevel2} > ${item.OptionMenuLevel3}`}</td>
                                        <td>
                                          <div className="form-check form-switch">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={item.Apply}
                                              disabled={actionMode === "C"}
                                              onChange={(e) => {
                                                console.log(e.target.checked);
                                                updateFeature(
                                                  item,
                                                  e.target.checked
                                                );
                                              }}
                                              name="Status"
                                              // onBlur={handleBlur}
                                            />
                                          </div>
                                          {item.Applied}
                                        </td>
                                      </tr>
                                      {openDetails &&
                                        openDetails.IdAppMenu === item.IdAppMenu && (
                                          <tr>
                                            <td colSpan={3}>
                                              <Card className="bg-light">
                                                <Card.Header>
                                                  {t("Permissions")}
                                                </Card.Header>
                                                <Card.Body></Card.Body>
                                              </Card>
                                            </td>
                                          </tr>
                                        )}
                                    </>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gx-3 align-items-center justify-content-end">
                {Object.values(formik.errors).length > 0 && (
                  <span className="invalid me-2">
                    Please check the forms for errors
                  </span>
                )}
                <button
                  className="btn  me-2"
                  type="button"
                  onClick={() => toggleOpen(null)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary "
                  type="button"
                  disabled={loading}
                  onClick={() => [formik.submitForm()]}
                >
                  {actionMode === "I" ? "Save & Continue" : "Save"}
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
      {section?.FormPath && <Breadcrumbs pathTitle={section?.FormPath} />}
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              <React.Fragment>
                <div className="d-flex align-items-center my-1">
                  <i className={section.FormIcon + " me-3 fa-2x"}></i>
                  <div className="d-flex flex-column">
                    <h5 className={"my-1"}>{section.FormTitle}</h5>
                  </div>
                </div>
              </React.Fragment>
              {loading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              <div className="w-auto d-flex align-items-center">
                {actionMode === null ? (
                  <>
                    <button
                      className="btn btn-sm btn-primary my-1 me-2"
                      title="Create"
                      disabled={loading}
                      onClick={() => toggleOpen("I")}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </>
                ) : null}
                {selectedItem ? (
                  <>
                    <button
                      className="btn btn-sm  my-1 me-2"
                      title="Update"
                      disabled={loading}
                      onClick={() => toggleOpen("U")}
                      aria
                    >
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button
                      className="btn btn-sm  my-1 me-2"
                      title="View"
                      disabled={loading}
                      onClick={() => toggleOpen("C")}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </>
                ) : null}

                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {isPinned ? (
                      <Dropdown.Item
                        onClick={() => {
                          addFavorite();
                        }}
                      >
                        Remover de favoritos
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() => {
                          addFavorite();
                        }}
                      >
                        Agregar a favoritos
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    {selectedItem ? (
                      <Dropdown.Item
                        onClick={() => {
                          toggleOpen("D");
                        }}
                      >
                        Duplicar
                      </Dropdown.Item>
                    ) : null}

                    {actionMode === null ? (
                      <Dropdown.Item
                        onClick={() => {
                          exportToExcel();
                        }}
                      >
                        Export to Excel
                      </Dropdown.Item>
                    ) : null}
                    {actionMode === "C" ? (
                      <Dropdown.Item onClick={() => exportToPDF()}>
                        Export to PDF
                      </Dropdown.Item>
                    ) : null}

                    <Dropdown.Divider />
                    {selectedItem ? (
                      <Dropdown.Item onClick={() => deleteConfirm()}>
                        Delete
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {loadGRIDUI()}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
