import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import { parsedUser } from "../../../utils/GetCurrentUser";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import FieldSelector from "../../customComponents/FieldSelector";
import { useTranslation } from "react-i18next";
import { GetDataOfJSON } from "../../../utils/GetDataOfJSON";

const ApplyAdvance = (props) => {
  const user = parsedUser();
  const { t } = useTranslation();
  const toast = useToast();
  const [generalError, setGeneralError] = useState(null);
  const [validationsSchema, setValidationSchema] = useState(null);

  const fields = [
    {
      FieldName: "IdBillingService",
      FieldType: "varchar",
      FieldTitle: "Concept",
      cssClasss: "col-12 ",
      HtmlType: "select",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        '{"HashID":"09b641fb147d3bb4c7890e2a91349dfc14267d5633e646e34b6261157576ce5b"}',
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "Details",
      FieldType: "varchar",
      FieldTitle: "Details",
      cssClasss: "col-12 ",
      HtmlType: "textarea",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "DetailReferences",
      FieldType: "varchar",
      FieldTitle: "Reference",
      cssClasss: "col-12 ",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "PaymentReference1",
      FieldType: "varchar",
      FieldTitle: "Payment Reference 1",
      cssClasss: "col-12 ",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      Required: false,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "PaymentReference2",
      FieldType: "varchar",
      FieldTitle: "Payment Reference 2",
      cssClasss: "col-12 ",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      Required: false,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      IdAppForm: 64,
      Id: 6400005,
      FieldName: "IdPaymentType",
      FieldType: "varchar",
      FieldTitle: "Method of payment",
      cssClasss: "col-12",
      HtmlType: "select",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: null,
      DataSource:
        '{"HashID":"258f835999ba23ffb6602f6c2a5a244751f2aac60bb9d65a577df3d2fc9c693b"}',
      Required: true,
      Visible: true,
      ReadOnly: false
    },
    {
      IdAppForm: 64,
      Id: 6400004,
      FieldName: "Amount",
      FieldType: "number",
      FieldTitle: "Amount",
      cssClasss: "col-12 ",
      HtmlType: "number",
      PlaceHolder: "",
      DefaultValue: 0,
      value: 0,
      OptionValues: "",
      DataSource: null,
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
  ];

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
  };

  const handleSubmit = async (obj, event) => {
    if (obj.Amount <= 0 || !obj.Amount) {
      setGeneralError("Amount must be greater than 0");
      toast({
        type: "error",
        message: "Amount must be greater than 0",
      });
      return;
    }

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;

      let queryData = {
        Data: `@Details=${obj.Details ? "'" + obj.Details + "'" : "null"
          },@DetailReferences=${obj.DetailReferences ? "'" + obj.DetailReferences + "'" : "null"
          }, @IdBillingService=${obj.IdBillingService ? obj.IdBillingService : "null"
          }, @Amount=${obj.Amount}, @GUIDReservationToFolio='${props.selectedFolio.GUIDReservationToFolio
          }', @IdPaymentType='${obj?.IdPaymentType}', @PaymentReference1='${obj?.PaymentReference1 || ""}', @PaymentReference2='${obj?.PaymentReference2 || ""}'`,
      };
      let request = await API.postAction(
        `api/cashier/ApplyAdvance?` + query,
        queryData ?? ""
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: "Record saved successfully",
      });

      props.toggle();
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    validateOnChange: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const checkCashierStatus = async () => {
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;

    let queryData = {
      Data: `@Accion='C'`,
    };
    console.log(queryData);

    let requestAPI = await API.postAction(
      "api/CashierClosing/generic?" + queryString,
      queryData
    );

    console.log("checks tatus ", requestAPI);

    if (requestAPI.status === 200) {
      const result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0);
      const status = result.Record[0].IsCashierOpen;
      if (!status) {
        toast({
          type: "error",
          message: "Can't execute the operation if the cashier is closed",
          width: "auto",
          height: "auto",
        });
        props.toggle();
        return;
      }

      const CashierBusinessUnit = Number(result.Record[0].CashierOpenIdBusinessUnit);
      const UserBusinessUnit = Number(user.IdBusinessUnit);

      if(CashierBusinessUnit !== UserBusinessUnit) {
        toast({
          type: "error",
          message: "You are not allowed to execute operations in this business unit",
          width: "auto",
          height: "auto",
        });
        props.toggle();
        return;
      }

      console.log(result);
    }
  };

  useEffect(() => {
    checkCashierStatus();
    prepareFormikObject();
  }, []);

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size={props.modalSize ?? "md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Deposit</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid className="overflow-auto table-responsive">
          <Row>
            <Col xs={12}>
              {fields &&
                fields
                  .filter(
                    (x) =>
                      x.FieldTitle !== "Audit Information" &&
                      x.FieldName !== "Id" &&
                      x.FieldName !== "IdCustomer" &&
                      x.FieldName !== "DateAdd" &&
                      x.FieldName !== "File" &&
                      x.FieldName !== "DateMod"
                  )
                  .map((item, index) => {
                    return (
                      <Col
                        className={
                          item.cssClasss + (!item.Visible ? " d-none" : "")
                        }
                        key={`col-${item.FieldName}-${index}`}
                      >
                        <label className="me-2 mt-2">
                          {item.FieldTitle}
                          {item.Required ? (
                            <i className="required-asterisk ms-1 text-danger">
                              *
                            </i>
                          ) : null}
                        </label>
                        <br />

                        <FieldSelector
                          actionMode={""}
                          model={item}
                          obj={formik.values}
                          key={`field-${item.FieldName}`}
                          updateField={updateField}
                          value={formik.values[item.FieldName]}
                        ></FieldSelector>
                        {formik.errors[item.FieldName] ? (
                          <div className="invalid text-sm my-1">
                            {formik.errors[item.FieldName]}
                          </div>
                        ) : null}
                      </Col>
                    );
                  })}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gx-3 align-items-center justify-content-end">
          {Object.values(formik.errors).length > 0 && (
            <span className="invalid me-2">
              Please check the forms for errors
            </span>
          )}
          <button className="btn me-2" type="button" onClick={props.toggle}>
            Close
          </button>
          <button
            className="btn btn-primary me-2"
            type="button"
            onClick={() => [formik.submitForm()]}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ApplyAdvance;
